import React from 'react'
import { Phone, Mail } from 'lucide-react'

export default function TopNav() {
  return (
    <div className="w-full bg-muted py-2 border-b border-gray-200">
      <div className="container flex items-center justify-between">
        <div>
          <img
            src="./mexico-flag.png"
            alt="Bandera de México"
            className="h-6 w-auto"
          />
        </div>
        <div className="flex items-center gap-4">
          <a href="tel:+525511016300" className="flex items-center gap-2 text-sm text-muted-foreground hover:text-primary">
            <Phone size={16} className="text-[#b7221c]" />
            +52 55 1101 6300
          </a>
          <a href="mailto:contacto@requena.org.mx" className="flex items-center gap-2 text-sm text-muted-foreground hover:text-primary">
            <Mail size={16} className="text-[#b7221c]" />
            contacto@requena.org.mx
          </a>
        </div>
      </div>
    </div>
  )
}
