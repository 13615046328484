import React from 'react'

export default function AboutSection() {
  const values = [
    { title: '' },
    { title: '' },
    { title: '' },
    { title: '' },
    { title: '' },
    { title: '' },
  ]

  return (
    <section className="relative w-full min-h-screen bg-stone-900 text-white">
      <div className="absolute inset-0">
        <img
          src="https://placehold.co/1920x1080?text=Background"
          alt="Background"
          className="w-full h-full object-cover opacity-20"
        />
      </div>
      
      <div className="relative container mx-auto px-4 py-20">
        <div className="max-w-4xl mx-auto text-center space-y-16">
          {/* Convicciones */}
          <div className="space-y-6">
            <h2 className="text-3xl md:text-4xl font-bold leading-tight">
            ETICA JURIDICA DENTRO DE GUERECA ABOGADOS 
            </h2>
            <p className="text-xl md:text-2xl">
            Conforme a la Oficina del Alto Comisionado de las Naciones Unidas para los Derechos Humanos (ACNUDH) existen “Principios Básicos sobre la Función de los Abogados” los cuales son los ejes rectores de nuestra firma. 
            </p>
          </div>

          {/* Objetivo */}
          <div className="space-y-4">
            <h3 className="text-2xl font-bold text-primary-600"></h3>
            <p className="text-lg">
            Fundamentos Internacionales: Los documentos internacionales como la Carta de las Naciones Unidas, la Declaración Universal de Derechos Humanos, y varios pactos y principios de derechos humanos, establecen la base para los derechos legales y el acceso a la justicia, incluyendo el derecho a la asistencia letrada, la igualdad ante la ley y la presunción de inocencia.
            </p>
            <p className="text-lg">
            Acceso Universal a Servicios Jurídicos: Se subraya la necesidad de que todos los individuos tengan acceso a servicios jurídicos sin discriminación, promoviendo la creación de mecanismos y fondos para asistencia legal, especialmente para los más desfavorecidos.
            </p>
            <p className="text-lg">
            Salvaguardas en Procedimientos Penales: Los gobiernos deben asegurar que las personas acusadas o detenidas sean informadas de su derecho a un abogado, tengan acceso a asistencia legal gratuita si no pueden pagarla, y dispongan de tiempo y condiciones adecuadas para consultar confidencialmente con su abogado.
            </p>
            <p className="text-lg">
            Formación y Ética Profesional: Es crucial la formación adecuada de los abogados, asegurando que no exista discriminación en el acceso a la profesión o en su ejercicio, e inculcando una conciencia ética y de derechos humanos.
            </p>
            <p className="text-lg">
            Protección y Libertades para Abogados: Los abogados deben poder ejercer su profesión sin intimidaciones o interferencias, gozar de libertad de expresión y asociación, y tener garantías contra represalias por sus actividades legales. Además, se destaca la importancia de las asociaciones profesionales de abogados para la regulación y protección de la profesión.
            </p>
          </div>

          {/* Valores */}
          <div className="space-y-8">
            <h3 className="text-2xl font-bold text-primary-600"></h3>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-6">
              {values.map((value, index) => (
                <div key={index} className="text-center">
                  <p className="text-lg font-medium">{value.title}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
