import React, { useState } from 'react'
import { Menu, X } from 'lucide-react'

export default function MainNav() {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  return (
    <header className="w-full border-b">
      <div className="container mx-auto px-4 py-4 flex items-center justify-between">
        <a href="/" className="flex items-center">
          <img src="./logo.png" alt="Logo" className="h-16 w-auto" />
        </a>

        {/* Mobile menu button */}
        <button
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          className="lg:hidden p-2 text-stone-600 hover:text-stone-900"
        >
          {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
        </button>

        {/* Desktop Navigation */}
        <nav className="hidden lg:flex items-center gap-6">
          <a href="#areas-practica" className="text-sm font-medium hover:text-primary transition-colors">
            ÁREAS DE PRÁCTICA
          </a>
          <a href="/about" className="text-sm font-medium hover:text-primary transition-colors">
            CAPACITACIÓN
          </a>
          <a href="/publications" className="text-sm font-medium hover:text-primary transition-colors">
            INTEGRANTES
          </a>
          <a href="/gallery" className="text-sm font-medium hover:text-primary transition-colors">
            PUBLICACIONES
          </a>
          <button className="rounded-md bg-primary px-4 py-2 text-sm font-medium text-white hover:bg-primary/90 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 whitespace-nowrap">
            
          </button>
        </nav>

        {/* Mobile Navigation */}
        {isMenuOpen && (
          <div className="absolute top-16 left-0 right-0 bg-white border-b shadow-lg lg:hidden z-50">
            <nav className="container mx-auto px-4 py-4 flex flex-col gap-4">
              <a href="#areas-practica" className="text-sm font-medium hover:text-primary py-2 transition-colors">
                ÁREAS DE PRÁCTICA
              </a>
              <a href="/about" className="text-sm font-medium hover:text-primary py-2 transition-colors">
                CAPACITACIÓN
              </a>
              <a href="/publications" className="text-sm font-medium hover:text-primary py-2 transition-colors">
                INTEGRANTES
              </a>
              <a href="/gallery" className="text-sm font-medium hover:text-primary py-2 transition-colors">
                PUBLICACIONES
              </a>
              <button className="rounded-md bg-primary px-4 py-2 text-sm font-medium text-white hover:bg-primary/90 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2">
                
              </button>
            </nav>
          </div>
        )}
      </div>
    </header>
  )
}
