import React, { useState } from 'react'

export default function ContactForm() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  })

  function handleChange(e) {
    const { name, value } = e.target
    setFormData(prev => ({
      ...prev,
      [name]: value
    }))
  }

  function handleSubmit(e) {
    e.preventDefault()
    const { name, email, message } = formData
    const mailtoLink = `mailto:contacto@requena.org.mx?subject=Contacto de ${name}&body=Nombre: ${name}%0D%0AEmail: ${email}%0D%0A%0D%0AMensaje:%0D%0A${message}`
    window.location.href = mailtoLink
  }

  return (
    <div className="w-full py-12 md:py-16 lg:py-20 ">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 ">
        <div className="mx-auto max-w-2xl bg-[url('./pen-bg.jpg')] bg-cover bg-center bg-no-repeat p-8 border border-gray-200 rounded-lg shadow-lg relative pb-60">
          <div className="text-center mb-8">
            <img
              src="./logo.png"
              alt="Requena Abogados"
              className=" align-left tmx-auto h-16"
            />
          </div>
          <h2 className="text-base text-left font-medium  mb-8">CONTÁCTANOS</h2>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Nombre*"
                required
                className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:border-[#b7221c]"
              />
            </div>
            <div>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Correo electrónico*"
                required
                className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:border-[#b7221c]"
              />
            </div>
            <div>
              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                placeholder="Mensaje"
                rows="4"
                className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:border-[#b7221c]"
              ></textarea>
            </div>
            <div className="text-left">
              <button
                type="submit"
                className="bg-[#b7221c] text-white px-8 py-2 rounded hover:bg-[#8a1915] transition-colors"
              >
                ENVIAR
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
