import React, { useState } from 'react'
import { X } from 'lucide-react'

function Modal({ isOpen, onClose, title, description, image, listItems }) {
  if (!isOpen) return null

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg max-w-2xl w-full max-h-[90vh] overflow-y-auto">
        <div className="p-6">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-2xl font-bold text-[#b7221c]">{title}</h3>
            <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
              <X size={24} />
            </button>
          </div>
          {image && (
            <img src={image} alt={title} className="w-full h-64 object-cover mb-4 rounded" />
          )}
          <div className="prose max-w-none">
            <p className="mb-4">{description}</p>
            {listItems && listItems.length > 0 && (
              <ul className="list-disc list-inside space-y-2">
                {listItems.map((item, index) => (
                  <li key={index} className="text-Black">{item}</li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

function PracticeArea({ title, subtitle, description, image, listItems }) {
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <>
      <div 
        className="p-6 rounded-lg cursor-pointer hover:bg-gray-100 transition-colors"
        onClick={() => setIsModalOpen(true)}
      >
        <h3 className="text-xl font-bold text-[#b7221c] text-center mb-2">{title}</h3>
        <p className="text-black font-bold text-center text-sm">{subtitle}</p>
      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={title}
        description={description}
        image={image}
        listItems={listItems}
      />
    </>
  )
}

const areas = [
  {
    title: "PENAL",
    subtitle: "DEFENSA EXITOSA DE PERSONAS IMPUTADAS",
    description: "En este despacho nos caracterizamos por hacer defensas exitosas de personas imputadas, acusadas de algún delito en primera instancia, apelación o amparo. También nuestra área de expertiz es en personas sentenciadas privadas de su libertad que quieran obtener algún beneficio derivado de la Ley de Ejecución Penal o que tengan problemas con la autoridad penitenciaria.",
    image: "https://placehold.co/800x600?text=Image",
    listItems: [
      "Fiscales",
      "Servidores públicos",
      "Financieros",
      "Ambientales",
      "Médicos y profesionales de la salud",
      "Licitantes, proveedores y contratistas del sector público y privado"
    ]
  },
  {
    title: "FISCAL",
    subtitle: "ABOGADOS ESPECIALIZADOS EN LA DEFENSA FISCAL",
    description: "Contamos con abogados especializados en la defensa fiscal, los despachos actualmente para poder tener resultados positivos para sus clientes necesitan no solamente conocer la normatividad fiscal,  sino entender las políticas estatales para estudiar  las tendencias en las resoluciones.",
    image: "https://placehold.co/800x600?text=Image",
    listItems: [
      "Litigio de créditos fiscales",
      "Acuerdos conclusivos (mediación fiscal)"
    ]
  },
  {
    title: "ADUANAL",
    subtitle: "CONSULTA Y LITIGACIÓN ADUANAL.",
    description: "Las aduanas constituyen uno de los recintos en donde más dinero mercancías y relaciones comerciales se desarrollan. Se requiere para todos aquellos que estén vinculados a este sector contar con el respaldo de personas que en efecto cuenten con la experiencia y conocimiento necesario para poder dar consultas y litigar con efectos positivos los probables conflictos que se puedan dar así como el desarrollo de estrategias preventivas.",
    image: "https://placehold.co/800x600?text=Image",
    listItems: [
      "Servicios de despacho aduanal",
      "Trámites Gubernamentales"
    ]
  },
  {
    title: "COMERCIO EXTERIOR",
    subtitle: "ABOGADOS ESPECIALIZADOS EN COMERCIO EXTERIOR",
    description: "El derecho del comercio exterior es un área de estudio muy poco explorada por los abogados en México, sin embargo para los empresarios resulta ser de uso obligado los servicios jurídicos de un despacho que cuente con la infraestructura necesaria para poder hacer gestiones, dar asesorías y resolver conflictos para el efecto de que las corporaciones se dediquen a lo que saben hacer, que es justamente los negocios internacionales. Algunos ejemplos de los servicios que podemos dar son los siguientes:",
    image: "https://placehold.co/800x600?text=Image",
    listItems: [
      "Programa para la Industria Manufacturera, Maquiladora y de Servicios de Exportación (IMMEX)",
      "Programa de seguridad de la cadena de suministro (BASC) y (CTPAT)",
      "Depósitos Fiscalizados Estratégicos",
      "Asesoria para la certificación IATA"
    ]
  },
  {
    title: "MIGRATORIO",
    subtitle: "ASESORÍA Y TRÁMITES PARA LA MIGRACIÓN",
    description: "Consideramos que actualmente en nuestro país se está viviendo uno de los momentos más importantes por lo que hace a la migración, se observa cada día más gente de otros países que llega a México, todos ellos requieren de una debida asesoría y de trámites específicos para poder mantenerse sin problemas con la autoridad y en caso de que algún extranjero llegue a presentar estos problemas y requiera de atención inmediata nosotros contamos con las personas adecuadas para asistirlos.",
    image: "https://placehold.co/800x600?text=Image",
    listItems: [
      "Residencias",
      "Empresas empleadoras",
      "Visas",
      "Asilados",
      "Refugiados"
    ]
  },
  {
    title: "MEDIACIÓN CORPORATIVA",
    subtitle: "RESOLUCIÓN DE CONFLICTOS POR MEDIO DE DÍALOGOS",
    description: "La propia sinergia de los asuntos que hemos atendido dentro de nuestro despacho, nos ha llevado a crear esta área específica que muchos beneficios ha traído a empresas que están dispuestas a resolver sus asuntos por medio del diálogo, el cual debe de ser debidamente enfocado y dirigido por profesionales en esta materia.",
    image: "https://placehold.co/800x600?text=Image",
    listItems: [
      "Manejo y solución de conflictos internos de una empresa",
      "Manejo y solución de conflictos contra gobierno u otras entidades"
    ]
  },
  {
    title: "COMPLIANCE",
    subtitle: "CUMPLIMIENTO NORMATIVO EN MATERIA PENAL",
    description: "La cultura de la legalidad la promoción del respeto de los derechos humanos la creación de instrumentos de control y el compromiso de una entidad respecto de sus relaciones con el gobierno y con otras empresas es una realidad en México actualmente todas las corporaciones deben de realizar sus programas de cumplimiento normativo no solamente en materia penal sino también en cuestiones laborales y aduanales en nuestro despacho podemos asesorar y el laboral esos instrumentos.",
    image: "https://placehold.co/800x600?text=Image",
    listItems: [
      "7.1 Penal",
      "7.2 Aduanal",
      "7.3 Laboral"
    ]
  },
  {
    title: "ADMINISTRATIVO",
    subtitle: "LITIGACIÓN EN MATERIA ADMINISTRATIVA.",
    description: "Existen en nuestro país una inmensidad de autoridades administrativas, las cual es pueden emitir resoluciones o tener actos que afecten a las empresas o bien a los gobernados, el arte de litigar la materia administrativa está precisamente en conocer de manera integral las diferencias sustanciales entre el tipo de acto, la autoridad ya sea local o federal y las facultades que ostentan. En este despacho nos hemos enfocado en gran medida en los siguientes asuntos.",
    image: "https://placehold.co/800x600?text=Image",
    listItems: [
      "Defensa de servidores públicos",
      "Sistema Nacional Anticorrupción"
    ]
  },
  {
    title: "GÉNERO",
    subtitle: "ASESORÍA CON ENFOQUE DE GÉNERO.",
    description: "La perspectiva de género es una metodología para poder abordar los asuntos y es parte de una agenda pública existen infinidad de conflictos vinculados a este tópico en nuestro despacho contamos con las personas indicadas para poder dar la asesoría y desarrollar programas gubernamentales o empresariales con enfoque de género.",
    image: "https://placehold.co/800x600?text=Image"
  },
]

export default function PracticeAreas() {
  return (
    <section id="areas-practica" className="py-16 bg-gray-50">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-[#b7221c] text-center mb-12">ÁREAS DE PRÁCTICA</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {areas.map((area, index) => (
            <PracticeArea key={index} {...area} />
          ))}
        </div>
      </div>
    </section>
  )
}
